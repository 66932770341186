
import trainingType from '@/store/modules/trainingType'
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'gridItem',
  props: {
    item: {
      required: true,
      type: Object,
      default: {},
    },
  },
  computed: {
    itemTrainingType (): trainingType {
      return this.item.logisticSession.trainingSessions[0].trainingType
    },
  },
})
